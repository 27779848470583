$maxWidth: 1320px;
$rightMenuWidth: 33vw;
$rightMenuWidthMobile: 83vw;
$headerHeight: 68px;
$colorHeavy: #383649;
$colorLight: #757575;
$mainFontColor: #484848;
$footerHeight: 671px;
$footerFontColor: #8F9CAB;
$footerFontColorMobile: #A7A6B5;
$footerBgColor: #223347;
$feelgoodBlue: #5488C7;
$feelgoodYellow: #F4A418;
$zIndexBgItem: -1;
$zIndexContent: 1;
$zIndexSlideLinks: 7;
$zIndexTopBtn: 8;
$zIndexHeader: 9;
$zIndexDrawerMenu: 10;
$zIndexModal: 11;
$titleBg: #2E75CC;
$slideHeight: 100vh;

$breakpoint-mobile: 905px;


@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin mainServiceTitle {
  height: 62px;
  background-color: $titleBg;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 3.2rem;
  letter-spacing: -0.05em;
  color: white;
  border-radius: 31px;
  @include filterDropshadow(4px, 4px);
}

@mixin mainServiceTitleMobile {
  background-color: $titleBg;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.05em;
  color: white;
  border-radius: 100px;
  @include filterDropshadow(4px, 4px);
}

@mixin mainServiceSummary {
  color: $mainFontColor;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin mainServiceSummaryMobile {
  color: $mainFontColor;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin mainServiceDesc {
  color: $colorLight;
  font-weight: normal;
  font-size: 2rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
}

@mixin mainServiceDescMobile {
  color: $colorLight;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.8;
  letter-spacing: -0.05em;
}

@mixin mainSubLink {
  color: $titleBg;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin mainSubLinkMobile {
  color: $titleBg;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.25));
  -webkit-transform: translateZ(0);
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
  -webkit-transform: translateZ(0);
}

@mixin linkRightArrow($color, $size) {
  -webkit-mask: url("/assets/images/right_arrow.svg") no-repeat center;
  mask: url("/assets/images/right_arrow.svg") no-repeat center;
  -webkit-mask-size: $size;
  mask-size: $size;
  background-color: $color;
}

@mixin articleLine {
  width: 50px;
  height: 6px;
  background-color: $titleBg;
}

@mixin articleTitle {
  font-weight: bold;
  font-size: 3.2rem;
  line-height: 1.6;
  letter-spacing: -0.05em;
  color: $titleBg;
}

@mixin articleSummary {
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
  color: $mainFontColor;
}

@mixin articleDesc {
  font-weight: normal;
  font-size: 2rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
  color: $colorLight;
}

@mixin articleNotice {
  font-size: 2.4rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
  color: $titleBg;
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin removeMask {
  mask: none;
  -webkit-mask: none;
  background-color: transparent;
}

@mixin showFGShape($url) {
  content: "";
  background-image: $url;
  background-size: 41px 70px;
  background-position: -41px -70px;
  width: 41px;
  height: 70px;
  margin: {
    right: 30px;
  };
  display: inline-block;
}

@mixin showFGShapeMobile($url) {
  content: "";
  background-image: $url;
  background-size: 28px 48px;
  background-repeat: no-repeat;
  width: 28px;
  height: 48px;
  display: inline-block;
}

@mixin link($color) {
  color: $color;
  border-bottom: 4px solid $color;
}

@mixin linkMobile($color) {
  border-bottom: 2px solid $color;
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$breakpoint-mobile + 1px}) {
    @content;
  }
}
@include mobile {
  .footer {
    //height: 240px;
    display: flex;
    flex-direction: column;

    > .inquiry {
      width: 100%;
      padding: 60px 29px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      > .contactUs {
        display: flex;
        flex-direction: column;

        > .sign {
          font-weight: bold;
          font-size: 28px;
          line-height: 1.6;
          color: $titleBg;
        }

        > .text {
          font-weight: normal;
          font-size: 16px;
          line-height: 1.8;
          letter-spacing: -0.05em;
          color: $colorLight;
        }
      }

      > .contactWays {
        color: $colorLight;
        font-weight: normal;
        font-size: 14px;
        line-height: 1.8;
        letter-spacing: -0.03em;
        display: flex;
        flex-direction: column;

        > .way {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 10px;
          width: 100%;

          &.email {
          }

          &.operatingTime {
          }

          > dt {
            width: 85px;
            flex-shrink: 0;
            display: flex;
            gap: 10px;

            > img {
              width: 25px;
              height: 25px;
            }
          }

          > dd {
          }
        }
      }
    }

    > .companyInfo {
      width: 100%;
      background-color: $footerBgColor;

      > .content {
        height: 100%;
        display: flex;
        flex-direction: column-reverse;
        gap: 12px;
        padding: 50px 30px;

        > .textArea {
          display: flex;
          flex-direction: column;
          gap: 8px;

          > .text {
            line-height: 1.6;
            font-size: 12px;

            > .companyName {
              color: $footerFontColorMobile;
              font-weight: bold;
            }

            > .otherInfo {
              color: $footerFontColorMobile;
              font-weight: normal;
              line-height: 1.4;
            }
          }

          > .language {
            margin-top: 10px;

            > label {
              font-weight: bold;
              font-size: 1.6rem;
              line-height: 1.8;
              color: #8f9cab;
            }

            > select {
              width: 160px;
              height: 37px;
              margin-left: 20px;
              border: 1px solid $footerFontColor;
              padding: {
                left: 20px;
                right: 20px;
                top: 4px;
                bottom: 4px;
              }
              background-color: transparent;
              background-image: url('../images/main/select_arrow.svg');
              background-repeat: no-repeat;
              background-position-x: calc(100% - 4px);
              background-position-y: 4px;
              color: $footerFontColor;
              font-size: 1.6rem;
              line-height: 1.8;
              font-weight: normal;

              &:focus {
                border-radius: 0;
              }
            }
          }
        }

        > .image {
          width: 102px;
          height: 16px;
          -webkit-mask: url('../images/logo/feelgood_logo.svg') no-repeat center;
          mask: url('../images/logo/feelgood_logo.svg') no-repeat center;
          mask-size: 102px 16px;
          background-color: $footerFontColorMobile;
        }
      }
    }

    > .inquiry.en {
      > .contactWays {
        > .way {
          > dt {
            width: 100px;
            gap: 5px;
          }
        }
      }
    }
  }
}

@include desktop {
  .footer {
    display: flex;
    flex-direction: column;
    height: auto !important;

    > .transparentBg {
      margin-top: #{'min(calc(671px - 100vh), 0px)'};
      height: #{'max(calc(100vh - 671px), 0px)'};
      background-color: transparent;
    }

    > .inquiry {
      @include desktopWidth;
      height: 348px;
      width: 100%;
      padding: {
        left: 120px;
        right: 149px;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;

      > .contactUs {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        justify-content: center;

        > .sign {
          font-weight: bold;
          font-size: 60px;
          line-height: 1.6;
          color: $titleBg;
        }

        > .text {
          font-weight: normal;
          font-size: 2rem;
          line-height: 1.8;
          letter-spacing: -0.05em;
          color: $colorLight;
        }
      }

      > .contactWays {
        color: $colorLight;
        font-weight: normal;
        font-size: 2rem;
        line-height: 1.8;
        letter-spacing: -0.05em;
        display: flex;
        flex-direction: column;
        gap: 20px;

        > .way {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 16px;

          &.email {
            @include showBottomToTop(0.5s, 0.5s, 10%);
          }

          &.operatingTime {
            @include showBottomToTop(0.5s, 1s, 10%);
          }

          > dt {
            display: flex;
            align-items: center;
            gap: 24px;
            width: 140px;
            flex-shrink: 0;

            > img {
              width: 36px;
              height: 36px;
            }
          }

          > dd {
          }
        }
      }
    }

    > .companyInfo {
      width: 100%;
      height: 323px;
      background-color: $footerBgColor;

      > .content {
        @include desktopWidth;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: {
          left: 60px;
          right: 71px;
        }

        > .textArea {
          display: flex;
          flex-direction: column;

          > .text {
            line-height: 1.8;
            font-size: 1.6rem;

            > .companyName {
              color: white;
              font-weight: bold;
            }

            > .otherInfo {
              color: $footerFontColor;
              font-weight: normal;
              // display: grid;
              grid-template-columns: repeat(2, auto);
              grid-template-rows: repeat(3, 1fr);
              column-gap: 29px;

              > .allRow {
                grid-column: 1 / 3;
              }

              > .breakMobile {
                display: none;
              }
            }
          }

          > .language {
            margin-top: 30px;

            > label {
              font-weight: bold;
              font-size: 1.6rem;
              line-height: 1.8;
              color: #8f9cab;
            }

            > select {
              width: 160px;
              height: 37px;
              margin-left: 20px;
              border: 1px solid $footerFontColor;
              padding: {
                left: 20px;
                right: 20px;
                top: 4px;
                bottom: 4px;
              }
              background-color: transparent;
              background-image: url('../images/main/select_arrow.svg');
              background-repeat: no-repeat;
              background-position-x: calc(100% - 4px);
              background-position-y: 4px;
              color: $footerFontColor;
              font-size: 1.6rem;
              line-height: 1.8;
              font-weight: normal;

              &:focus {
                border-radius: 0;
              }
            }
          }
        }

        > .image {
          width: 225px;
          height: 36px;
          -webkit-mask: url('../images/logo/feelgood_logo.svg') no-repeat center;
          mask: url('../images/logo/feelgood_logo.svg') no-repeat center;
          background-color: $footerFontColor;
        }
      }
    }

    > .inquiry.en {
      > .contactWays {
        > .way {
          > dt {
            width: 155px;
          }
        }
      }
    }
  }
}
