$maxWidth: 1320px;
$rightMenuWidth: 33vw;
$rightMenuWidthMobile: 83vw;
$headerHeight: 68px;
$colorHeavy: #383649;
$colorLight: #757575;
$mainFontColor: #484848;
$footerHeight: 671px;
$footerFontColor: #8F9CAB;
$footerFontColorMobile: #A7A6B5;
$footerBgColor: #223347;
$feelgoodBlue: #5488C7;
$feelgoodYellow: #F4A418;
$zIndexBgItem: -1;
$zIndexContent: 1;
$zIndexSlideLinks: 7;
$zIndexTopBtn: 8;
$zIndexHeader: 9;
$zIndexDrawerMenu: 10;
$zIndexModal: 11;
$titleBg: #2E75CC;
$slideHeight: 100vh;

$breakpoint-mobile: 905px;


@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin mainServiceTitle {
  height: 62px;
  background-color: $titleBg;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 3.2rem;
  letter-spacing: -0.05em;
  color: white;
  border-radius: 31px;
  @include filterDropshadow(4px, 4px);
}

@mixin mainServiceTitleMobile {
  background-color: $titleBg;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.05em;
  color: white;
  border-radius: 100px;
  @include filterDropshadow(4px, 4px);
}

@mixin mainServiceSummary {
  color: $mainFontColor;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin mainServiceSummaryMobile {
  color: $mainFontColor;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin mainServiceDesc {
  color: $colorLight;
  font-weight: normal;
  font-size: 2rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
}

@mixin mainServiceDescMobile {
  color: $colorLight;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.8;
  letter-spacing: -0.05em;
}

@mixin mainSubLink {
  color: $titleBg;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin mainSubLinkMobile {
  color: $titleBg;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.25));
  -webkit-transform: translateZ(0);
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
  -webkit-transform: translateZ(0);
}

@mixin linkRightArrow($color, $size) {
  -webkit-mask: url("/assets/images/right_arrow.svg") no-repeat center;
  mask: url("/assets/images/right_arrow.svg") no-repeat center;
  -webkit-mask-size: $size;
  mask-size: $size;
  background-color: $color;
}

@mixin articleLine {
  width: 50px;
  height: 6px;
  background-color: $titleBg;
}

@mixin articleTitle {
  font-weight: bold;
  font-size: 3.2rem;
  line-height: 1.6;
  letter-spacing: -0.05em;
  color: $titleBg;
}

@mixin articleSummary {
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
  color: $mainFontColor;
}

@mixin articleDesc {
  font-weight: normal;
  font-size: 2rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
  color: $colorLight;
}

@mixin articleNotice {
  font-size: 2.4rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
  color: $titleBg;
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin removeMask {
  mask: none;
  -webkit-mask: none;
  background-color: transparent;
}

@mixin showFGShape($url) {
  content: "";
  background-image: $url;
  background-size: 41px 70px;
  background-position: -41px -70px;
  width: 41px;
  height: 70px;
  margin: {
    right: 30px;
  };
  display: inline-block;
}

@mixin showFGShapeMobile($url) {
  content: "";
  background-image: $url;
  background-size: 28px 48px;
  background-repeat: no-repeat;
  width: 28px;
  height: 48px;
  display: inline-block;
}

@mixin link($color) {
  color: $color;
  border-bottom: 4px solid $color;
}

@mixin linkMobile($color) {
  border-bottom: 2px solid $color;
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$breakpoint-mobile + 1px}) {
    @content;
  }
}
@import "src/assets/scss/variables";

@include mobile {
  .aboutFeelGoodWrapper {
    height: 100vh;
    width: 100vw;
    z-index: $zIndexContent;

    > .introduction {
      height: 100vh;
      background-image: url("../../images/main/about_feelgood_bg_01.png");
      background-size: cover;
      background-position: center;
      position: relative;
      display: flex;
      flex-direction: column;
      padding-top: $headerHeight;

      > .image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.65);
      }

      > .phrase {
        padding: 0 30px;
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: $zIndexContent;
        gap: 40px;
        white-space: normal;

        > .title {
          width: 210px;
          height: 44px;
          background-color: $titleBg;
          color: white;
          @include filterDropshadow(4px, 4px);
          border-radius: 100px;

          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.05em;

          display: flex;
          justify-content: center;
          align-items: center;

          &.ru {
            padding: 10px 40px;
            width: fit-content;
            height: fit-content;
            text-align: center;
          }
        }

        > .texts {
          display: flex;
          flex-direction: column;
          gap: 10px;

          > .summary {
            color: white;
            font-weight: bold;
            font-size: 16px;
            line-height: 1.6;
            letter-spacing: -0.05em;
            @include filterDropshadow(4px, 4px);
            text-align: center;
          }

          > .desc {
            color: white;
            font-weight: normal;
            font-size: 14px;
            line-height: 1.8;
            letter-spacing: -0.05em;
            @include filterDropshadow(4px, 4px);
            text-align: center;
          }
        }
      }
    }
  }
}

@include desktop {
  .aboutFeelGoodWrapper {
    height: 100vh;
    width: 100vw;
    z-index: $zIndexContent;

    > .introduction {
      height: 100vh;
      background-image: url("../../images/main/about_feelgood_bg_01.png");
      background-size: cover;
      background-position: center;
      position: relative;
      display: flex;
      flex-direction: column;
      padding-top: $headerHeight;

      > .image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.65);
      }

      > .phrase {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: $zIndexContent;

        > .title {
          width: 373px;
          height: 62px;
          background-color: $titleBg;
          color: white;
          @include filterDropshadow(4px, 4px);
          border-radius: 31px;

          font-weight: bold;
          font-size: 3.2rem;
          letter-spacing: -0.05em;

          display: flex;
          justify-content: center;
          align-items: center;

          &.ru {
            padding: 10px 40px;
            width: fit-content;
            height: fit-content;
          }
        }

        > .texts {
          text-align: center;
          @include showBottomToTop(0.5s, 0.5s, 20%);

          > .summary {
            margin: {
              top: 70px;
            };
            color: white;
            font-weight: bold;
            font-size: 2.4rem;
            line-height: 1.6;
            letter-spacing: -0.05em;
            @include filterDropshadow(4px, 4px);
          }

          > .desc {
            margin: {
              top: 30px;
            };
            color: white;
            font-weight: normal;
            font-size: 2rem;
            line-height: 1.8;
            letter-spacing: -0.05em;
            @include filterDropshadow(4px, 4px);
          }
        }
      }
    }
  }
}
