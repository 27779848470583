$maxWidth: 1320px;
$rightMenuWidth: 33vw;
$rightMenuWidthMobile: 83vw;
$headerHeight: 68px;
$colorHeavy: #383649;
$colorLight: #757575;
$mainFontColor: #484848;
$footerHeight: 671px;
$footerFontColor: #8F9CAB;
$footerFontColorMobile: #A7A6B5;
$footerBgColor: #223347;
$feelgoodBlue: #5488C7;
$feelgoodYellow: #F4A418;
$zIndexBgItem: -1;
$zIndexContent: 1;
$zIndexSlideLinks: 7;
$zIndexTopBtn: 8;
$zIndexHeader: 9;
$zIndexDrawerMenu: 10;
$zIndexModal: 11;
$titleBg: #2E75CC;
$slideHeight: 100vh;

$breakpoint-mobile: 905px;


@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin mainServiceTitle {
  height: 62px;
  background-color: $titleBg;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 3.2rem;
  letter-spacing: -0.05em;
  color: white;
  border-radius: 31px;
  @include filterDropshadow(4px, 4px);
}

@mixin mainServiceTitleMobile {
  background-color: $titleBg;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.05em;
  color: white;
  border-radius: 100px;
  @include filterDropshadow(4px, 4px);
}

@mixin mainServiceSummary {
  color: $mainFontColor;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin mainServiceSummaryMobile {
  color: $mainFontColor;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin mainServiceDesc {
  color: $colorLight;
  font-weight: normal;
  font-size: 2rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
}

@mixin mainServiceDescMobile {
  color: $colorLight;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.8;
  letter-spacing: -0.05em;
}

@mixin mainSubLink {
  color: $titleBg;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin mainSubLinkMobile {
  color: $titleBg;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.25));
  -webkit-transform: translateZ(0);
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
  -webkit-transform: translateZ(0);
}

@mixin linkRightArrow($color, $size) {
  -webkit-mask: url("/assets/images/right_arrow.svg") no-repeat center;
  mask: url("/assets/images/right_arrow.svg") no-repeat center;
  -webkit-mask-size: $size;
  mask-size: $size;
  background-color: $color;
}

@mixin articleLine {
  width: 50px;
  height: 6px;
  background-color: $titleBg;
}

@mixin articleTitle {
  font-weight: bold;
  font-size: 3.2rem;
  line-height: 1.6;
  letter-spacing: -0.05em;
  color: $titleBg;
}

@mixin articleSummary {
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
  color: $mainFontColor;
}

@mixin articleDesc {
  font-weight: normal;
  font-size: 2rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
  color: $colorLight;
}

@mixin articleNotice {
  font-size: 2.4rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
  color: $titleBg;
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin removeMask {
  mask: none;
  -webkit-mask: none;
  background-color: transparent;
}

@mixin showFGShape($url) {
  content: "";
  background-image: $url;
  background-size: 41px 70px;
  background-position: -41px -70px;
  width: 41px;
  height: 70px;
  margin: {
    right: 30px;
  };
  display: inline-block;
}

@mixin showFGShapeMobile($url) {
  content: "";
  background-image: $url;
  background-size: 28px 48px;
  background-repeat: no-repeat;
  width: 28px;
  height: 48px;
  display: inline-block;
}

@mixin link($color) {
  color: $color;
  border-bottom: 4px solid $color;
}

@mixin linkMobile($color) {
  border-bottom: 2px solid $color;
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$breakpoint-mobile + 1px}) {
    @content;
  }
}
@import "src/assets/scss/variables";

.wrapper {
  @include desktopWidth;
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: max-content;
  align-items: center;
  justify-content: center;

  > .buttonArea {
    width: 100%;
    padding: {
      left: 134px;
      right: 133px;
    };
    display: flex;
    justify-content: center;

    > .btn {
      width: 60px;
      height: 61px;

      &.hide {
        visibility: hidden;
      }
    }

    > .monthButtonsWrapper {
      width: 910px;
      height: 61px;
      overflow: hidden;
      position: relative;

      > .slider {
        position: absolute;
        top: 0;
        left: 0;
        width: max-content;
        height: max-content;
        display: flex;
        flex-flow: row nowrap;

        &.alignCenter {
          margin: {
            left: auto;
            right: auto;
          };
          position: static;
        }

        > .button {
          box-sizing: border-box;
          flex-shrink: 0;
          width: 162px;
          height: 61px;
          margin: {
            left: 10px;
            right: 10px;
          };
          cursor: pointer;
          font-weight: normal;
          font-size: 2.4rem;
          letter-spacing: -0.05em;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $colorLight;

          &:last-child {
          }

          &:hover {
            border: 4px solid $feelgoodYellow;
            border-radius: 30px;
          }

          &.selected {
            border: 4px solid $feelgoodYellow;
            border-radius: 30px;
            color: $titleBg;
            font-weight: bold;
          }
        }
      }
    }
  }

  > .selectedPhotoDesc {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include showBottomToTop(0.3s, 0.2s, 10%);

    > .title {
      font-weight: bold;
      font-size: 3.2rem;
      line-height: 1.6;
      letter-spacing: -0.05em;
      color: $colorHeavy;
    }

    > .desc {
      font-weight: normal;
      font-size: 2.4rem;
      line-height: 1.6;
      letter-spacing: -0.05em;
      margin-top: 20px;
      color: $mainFontColor;
    }
  }

  > .imageArea {
    @include showBottomToTop(0.3s, 0.5s, 10%);

    > .imgDetail {
      margin-top: 50px;
      width: 749px;
      height: 569px;
      background-color: #F4A418;
      margin: {
        left: auto;
        right: auto;
      };

      > img {
        width: 100%;
        height: 100%;
      }
    }

    > .monthImages {
      width: 748px;
      height: 129px;
      display: flex;
      margin: {
        top: 37px;
      };
      justify-content: space-between;

      > .imgButton {
        width: 170px;
        height: 129px;
        background-color: skyblue;
        position: relative;

        > .whiteBg {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background-color: rgba(255, 255, 255, 0.7);
        }

        &.selected {

          > .whiteBg {
            display: none;
          }
        }

        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

}