$maxWidth: 1320px;
$rightMenuWidth: 33vw;
$rightMenuWidthMobile: 83vw;
$headerHeight: 68px;
$colorHeavy: #383649;
$colorLight: #757575;
$mainFontColor: #484848;
$footerHeight: 671px;
$footerFontColor: #8F9CAB;
$footerFontColorMobile: #A7A6B5;
$footerBgColor: #223347;
$feelgoodBlue: #5488C7;
$feelgoodYellow: #F4A418;
$zIndexBgItem: -1;
$zIndexContent: 1;
$zIndexSlideLinks: 7;
$zIndexTopBtn: 8;
$zIndexHeader: 9;
$zIndexDrawerMenu: 10;
$zIndexModal: 11;
$titleBg: #2E75CC;
$slideHeight: 100vh;

$breakpoint-mobile: 905px;


@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin mainServiceTitle {
  height: 62px;
  background-color: $titleBg;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 3.2rem;
  letter-spacing: -0.05em;
  color: white;
  border-radius: 31px;
  @include filterDropshadow(4px, 4px);
}

@mixin mainServiceTitleMobile {
  background-color: $titleBg;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.05em;
  color: white;
  border-radius: 100px;
  @include filterDropshadow(4px, 4px);
}

@mixin mainServiceSummary {
  color: $mainFontColor;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin mainServiceSummaryMobile {
  color: $mainFontColor;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin mainServiceDesc {
  color: $colorLight;
  font-weight: normal;
  font-size: 2rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
}

@mixin mainServiceDescMobile {
  color: $colorLight;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.8;
  letter-spacing: -0.05em;
}

@mixin mainSubLink {
  color: $titleBg;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin mainSubLinkMobile {
  color: $titleBg;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.25));
  -webkit-transform: translateZ(0);
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
  -webkit-transform: translateZ(0);
}

@mixin linkRightArrow($color, $size) {
  -webkit-mask: url("/assets/images/right_arrow.svg") no-repeat center;
  mask: url("/assets/images/right_arrow.svg") no-repeat center;
  -webkit-mask-size: $size;
  mask-size: $size;
  background-color: $color;
}

@mixin articleLine {
  width: 50px;
  height: 6px;
  background-color: $titleBg;
}

@mixin articleTitle {
  font-weight: bold;
  font-size: 3.2rem;
  line-height: 1.6;
  letter-spacing: -0.05em;
  color: $titleBg;
}

@mixin articleSummary {
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
  color: $mainFontColor;
}

@mixin articleDesc {
  font-weight: normal;
  font-size: 2rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
  color: $colorLight;
}

@mixin articleNotice {
  font-size: 2.4rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
  color: $titleBg;
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin removeMask {
  mask: none;
  -webkit-mask: none;
  background-color: transparent;
}

@mixin showFGShape($url) {
  content: "";
  background-image: $url;
  background-size: 41px 70px;
  background-position: -41px -70px;
  width: 41px;
  height: 70px;
  margin: {
    right: 30px;
  };
  display: inline-block;
}

@mixin showFGShapeMobile($url) {
  content: "";
  background-image: $url;
  background-size: 28px 48px;
  background-repeat: no-repeat;
  width: 28px;
  height: 48px;
  display: inline-block;
}

@mixin link($color) {
  color: $color;
  border-bottom: 4px solid $color;
}

@mixin linkMobile($color) {
  border-bottom: 2px solid $color;
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$breakpoint-mobile + 1px}) {
    @content;
  }
}
@include mobile {
  .mainContentWrapper {
    height: 100vh;
    width: 100vw;
    padding: {
      top: $headerHeight;
    };

    > .mainContent {
      width: 100%;
      height: calc(100% + #{$headerHeight});
      margin: {
        top: -$headerHeight;
      };
      padding: {
        left: 30px;
        right: 30px;
        top: 100px;
        bottom: 20px;
      };
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      > .textArea {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        z-index: $zIndexContent;
        gap: 30px;

        > .text {
          font-size: 28px;
          font-weight: bold;
          line-height: 1.3;
          letter-spacing: -0.03em;
          color: $mainFontColor;
          display: flex;
          flex-direction: column;
          gap: 10px;
          align-items: flex-start;

          &.ru, &.en {
            font-size: 20px;
          }

          > .textLine1 {
            border-bottom: 2px solid $feelgoodBlue;
          }

          > .textLine2 {
            border-bottom: 2px solid $feelgoodYellow;
          }
        }

        > .logo {
          width: 199px;

          > img {
            width: 100%;
          }
        }
      }

      > .imageArea {
        width: 280px;
        height: 283px;
        position: relative;
        z-index: $zIndexContent;

        > .letterF {
          position: absolute;
          top: 0;
          left: 0;
          width: 142.87px;
          height: 188.79px;
          @include filterDropshadow(6px, 8px);

          > img {
            width: 100%;
            height: 100%;
          }
        }

        > .letterG {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 186.67px;
          height: 188.79px;
          @include filterDropshadow(6px, 8px);

          > img {
            width: 100%;
            height: 100%;
          }
        }
      }

      > .yellowBg {
        position: absolute;
        bottom: 0;
        left: 0;
        background: url("../../images/main/main_bg_01_mobile.svg") no-repeat;
        width: 360px;
        height: 299px;
      }

      > .blueBg {
        position: absolute;
        top: 0;
        right: 0;
        width: 168px;
        height: 146px;
        background: url("../../images/main/main_bg_02_mobile.svg") no-repeat;
      }
    }
  }
}

@include desktop {
  .mainContentWrapper {
    height: 100vh;
    width: 100vw;
    padding: {
      top: $headerHeight;
    };

    > .mainContent {
      max-width: $maxWidth;
      height: calc(100% + #{$headerHeight});
      margin: {
        top: -$headerHeight;
        left: auto;
        right: auto;
      };
      padding: {
        left: 100px;
      };
      position: relative;
      display: flex;
      align-items: center;

      > .textArea {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        //height: calc(100% + #{$headerHeight});
        //margin-top: -$headerHeight;
        z-index: $zIndexContent;
        flex: 1;

        > .text {
          font-size: 5.2rem;
          font-weight: bold;
          line-height: 1.3;
          letter-spacing: -0.03em;
          color: $mainFontColor;
          @include showBottomToTop(0.8s, 1.8s, 10%);

          > .textLine1 {
            display: block;
            width: 274px;
            height: 68px;
            border-bottom: 4px solid $feelgoodBlue;
            margin-bottom: 20px;
          }

          > .textLine2 {
            display: block;
            width: 327px;
            height: 68px;
            border-bottom: 4px solid $feelgoodYellow;
            margin-bottom: 20px;
          }
        }

        > .logo {
          margin-top: 70px;
          width: 370px;
          @include showBottomToTop(0.8s, 2.3s, 10%);

          > img {
            width: 100%;
          }
        }
      }

      > .imageArea {
        position: relative;
        flex: 1;
        z-index: $zIndexContent;
        width: 697px;
        height: 706px;

        > .letterF {
          position: absolute;
          top: 0;
          left: 0;
          width: 355.65px;
          height: 470.96px;
          @include filterDropshadow(14px, 20px);
          @include showBottomToTop(0.8s, 0.5s, 10%);
        }

        > .letterG {
          position: absolute;
          left: 232.33px;
          top: 235.04px;
          width: 464.67px;
          height: 470.96px;
          @include filterDropshadow(20px, 14px);
          @include showBottomToTop(0.8s, 1s, 10%);
        }
      }

      > .yellowBg {
        position: absolute;
        top: -$headerHeight;
        right: -341.52px;
        background: url("../../images/main/main_bg_01.svg") no-repeat;
        width: 1482.71px;
        height: 1096.72px;
      }

      > .blueBg {
        position: absolute;
        bottom: -441.11px;
        left: -919px;
        width: 1224.86px;
        height: 713.11px;
        background: url("../../images/main/main_bg_02.svg") no-repeat;
      }
    }
  }

  .mainContentWrapper.ru, .mainContentWrapper.en {
    color: red;
    > .mainContent {
      > .textArea {
        > .text {
          font-size: 3.2rem;

          > .textLine1 {
            width: unset;
            height: unset;
          }

          > .textLine2 {
            width: unset;
            height: unset;
          }
        }
      }
    }
  }
}