$maxWidth: 1320px;
$rightMenuWidth: 33vw;
$rightMenuWidthMobile: 83vw;
$headerHeight: 68px;
$colorHeavy: #383649;
$colorLight: #757575;
$mainFontColor: #484848;
$footerHeight: 671px;
$footerFontColor: #8F9CAB;
$footerFontColorMobile: #A7A6B5;
$footerBgColor: #223347;
$feelgoodBlue: #5488C7;
$feelgoodYellow: #F4A418;
$zIndexBgItem: -1;
$zIndexContent: 1;
$zIndexSlideLinks: 7;
$zIndexTopBtn: 8;
$zIndexHeader: 9;
$zIndexDrawerMenu: 10;
$zIndexModal: 11;
$titleBg: #2E75CC;
$slideHeight: 100vh;

$breakpoint-mobile: 905px;


@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin mainServiceTitle {
  height: 62px;
  background-color: $titleBg;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 3.2rem;
  letter-spacing: -0.05em;
  color: white;
  border-radius: 31px;
  @include filterDropshadow(4px, 4px);
}

@mixin mainServiceTitleMobile {
  background-color: $titleBg;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.05em;
  color: white;
  border-radius: 100px;
  @include filterDropshadow(4px, 4px);
}

@mixin mainServiceSummary {
  color: $mainFontColor;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin mainServiceSummaryMobile {
  color: $mainFontColor;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin mainServiceDesc {
  color: $colorLight;
  font-weight: normal;
  font-size: 2rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
}

@mixin mainServiceDescMobile {
  color: $colorLight;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.8;
  letter-spacing: -0.05em;
}

@mixin mainSubLink {
  color: $titleBg;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin mainSubLinkMobile {
  color: $titleBg;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.25));
  -webkit-transform: translateZ(0);
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
  -webkit-transform: translateZ(0);
}

@mixin linkRightArrow($color, $size) {
  -webkit-mask: url("/assets/images/right_arrow.svg") no-repeat center;
  mask: url("/assets/images/right_arrow.svg") no-repeat center;
  -webkit-mask-size: $size;
  mask-size: $size;
  background-color: $color;
}

@mixin articleLine {
  width: 50px;
  height: 6px;
  background-color: $titleBg;
}

@mixin articleTitle {
  font-weight: bold;
  font-size: 3.2rem;
  line-height: 1.6;
  letter-spacing: -0.05em;
  color: $titleBg;
}

@mixin articleSummary {
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
  color: $mainFontColor;
}

@mixin articleDesc {
  font-weight: normal;
  font-size: 2rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
  color: $colorLight;
}

@mixin articleNotice {
  font-size: 2.4rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
  color: $titleBg;
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin removeMask {
  mask: none;
  -webkit-mask: none;
  background-color: transparent;
}

@mixin showFGShape($url) {
  content: "";
  background-image: $url;
  background-size: 41px 70px;
  background-position: -41px -70px;
  width: 41px;
  height: 70px;
  margin: {
    right: 30px;
  };
  display: inline-block;
}

@mixin showFGShapeMobile($url) {
  content: "";
  background-image: $url;
  background-size: 28px 48px;
  background-repeat: no-repeat;
  width: 28px;
  height: 48px;
  display: inline-block;
}

@mixin link($color) {
  color: $color;
  border-bottom: 4px solid $color;
}

@mixin linkMobile($color) {
  border-bottom: 2px solid $color;
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$breakpoint-mobile + 1px}) {
    @content;
  }
}
@import "variables";

@mixin headerBgTransition {
  transition: background-color 0.4s ease-in-out;

  &.show {
    opacity: 1;
  }
}

@include mobile {
  .headerWrapper {
    background-color: transparent;
    width: 100%;
    height: $headerHeight;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    z-index: 8;
    transition: background-color 0.4s ease-in-out;

    &.show {
      background-color: white;
      @include filterDropshadowWithColor(4px, 10px, rgba(130, 120, 104, 0.2));
    }

    > .content {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 24px 18px;

      > .logo {
        display: block;
        width: 119px;
        height: 20px;
        visibility: hidden;

        > img {
          width: 100%;
        }

        &.show {
          visibility: visible;
        }
      }

      > .button {
        width: 40px;
        height: 40px;

        > img {
          width: 100%;
        }
      }
    }

    > .bg {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      width: 100vw;
      height: 100vh;

      &.show {
        display: block;
      }
    }

    > .menu {
      background-color: white;
      height: 100vh;
      padding: {
        left: 30px;
      };
      z-index: $zIndexDrawerMenu;
      position: fixed;
      right: 0;
      top: 0;
      transition: all 0.4s ease;
      transform: translateX($rightMenuWidthMobile);
      width: $rightMenuWidthMobile;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      &.show {
        transform: translateX(0);
      }

      > .closeBtnArea {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;

        > .closeBtn {
          width: 40px;
          height: 40px;
          display: block;
          margin: {
            top: 14px;
            right: 10px;
            left: auto;
          };

          > img {
            width: 100%;
          }
        }
      }


      > .nav {
        font-weight: bold;
        font-size: 14px;
        line-height: 1.6;
        letter-spacing: -0.05em;

        > ul {
          display: flex;
          flex-direction: column;
          gap: 44px;

          > li {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-left: 48px;

            > .link {
              display: inline-block;
              color: $colorLight;
              box-sizing: content-box;
              border-bottom: 4px solid transparent;
            }

            &.active {
              padding-left: 0;
              margin-left: 0;
            }

            &:nth-child(even).active {
              &::before {
                @include showFGShapeMobile(url("../images/nav/g_icon.svg"));
              }
              > .link {
                @include linkMobile($feelgoodBlue);
              }
            }

            &:nth-child(odd).active {
              &::before {
                @include showFGShapeMobile(url("../images/nav/f_icon.svg"));
              }
              > .link {
                @include linkMobile($feelgoodYellow);
              }
            }
          }
        }
      }
    }
  }
}

@include desktop {
  .headerWrapper {
    background-color: transparent;
    width: 100%;
    height: $headerHeight;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    z-index: 8;
    transition: background-color 0.4s ease-in-out;

    &.show {
      background-color: white;
      @include filterDropshadowWithColor(4px, 10px, rgba(130, 120, 104, 0.2));
    }

    > .content {
      display: flex;
      max-width: $maxWidth;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: {
        left: 70px;
        right: 70px;
      };

      > .logo {
        display: block;
        width: 169px;
        height: 28px;
        visibility: hidden;

        > img {
          width: 100%;
        }

        &.show {
          visibility: visible;
        }
      }

      > .button {
        width: 40px;
        height: 40px;

        > img {
          width: 100%;
        }
      }
    }

    > .bg {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      width: 100vw;
      height: 100vh;

      &.show {
        display: block;
      }
    }

    > .menu {
      background-color: white;
      height: 100vh;
      padding: {
        left: 60px;
      }
      z-index: $zIndexDrawerMenu;
      position: fixed;
      right: 0;
      top: 0;
      transition: all 0.4s ease;
      transform: translateX($rightMenuWidth);
      width: $rightMenuWidth;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      &.show {
        transform: translateX(0);
      }

      > .closeBtnArea {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;

        > .closeBtn {
          width: 40px;
          height: 40px;
          display: block;
          margin: {
            top: 5.6%;
            left: 75%;
          };

          > img {
            width: 100%;
          }
        }
      }


      > .nav {
        font-weight: bold;
        font-size: 2.4rem;
        line-height: 1.6;
        letter-spacing: -0.05em;

        > ul {

          > li {
            height: 70px;
            padding: {
              left: 30px;
              right: 30px;
              top: 16px;
              bottom: 16px;
            };
            margin: {
              left: 41px;
              bottom: 30px;
            };
            display: flex;
            align-items: center;

            &:last-child {
              margin-bottom: 0;
            }

            > .link {
              display: inline-block;
              height: 38px;
              color: $colorLight;
              box-sizing: content-box;
              border-bottom: 4px solid transparent;
            }



            &:hover {
              padding-left: 0;
              margin-left: 0;
            }

            &:nth-child(even):hover {
              &::before {
                @include showFGShape(url("../images/nav/g_icon.svg"));
              }
              > .link {
                @include link($feelgoodBlue);
              }
            }

            &:nth-child(odd):hover {
              &::before {
                @include showFGShape(url("../images/nav/f_icon.svg"));
              }
              > .link {
                @include link($feelgoodYellow);
              }
            }
          }
        }
      }
    }
  }
}