$maxWidth: 1320px;
$rightMenuWidth: 33vw;
$rightMenuWidthMobile: 83vw;
$headerHeight: 68px;
$colorHeavy: #383649;
$colorLight: #757575;
$mainFontColor: #484848;
$footerHeight: 671px;
$footerFontColor: #8F9CAB;
$footerFontColorMobile: #A7A6B5;
$footerBgColor: #223347;
$feelgoodBlue: #5488C7;
$feelgoodYellow: #F4A418;
$zIndexBgItem: -1;
$zIndexContent: 1;
$zIndexSlideLinks: 7;
$zIndexTopBtn: 8;
$zIndexHeader: 9;
$zIndexDrawerMenu: 10;
$zIndexModal: 11;
$titleBg: #2E75CC;
$slideHeight: 100vh;

$breakpoint-mobile: 905px;


@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin mainServiceTitle {
  height: 62px;
  background-color: $titleBg;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 3.2rem;
  letter-spacing: -0.05em;
  color: white;
  border-radius: 31px;
  @include filterDropshadow(4px, 4px);
}

@mixin mainServiceTitleMobile {
  background-color: $titleBg;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.05em;
  color: white;
  border-radius: 100px;
  @include filterDropshadow(4px, 4px);
}

@mixin mainServiceSummary {
  color: $mainFontColor;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin mainServiceSummaryMobile {
  color: $mainFontColor;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin mainServiceDesc {
  color: $colorLight;
  font-weight: normal;
  font-size: 2rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
}

@mixin mainServiceDescMobile {
  color: $colorLight;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.8;
  letter-spacing: -0.05em;
}

@mixin mainSubLink {
  color: $titleBg;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin mainSubLinkMobile {
  color: $titleBg;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.25));
  -webkit-transform: translateZ(0);
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
  -webkit-transform: translateZ(0);
}

@mixin linkRightArrow($color, $size) {
  -webkit-mask: url("/assets/images/right_arrow.svg") no-repeat center;
  mask: url("/assets/images/right_arrow.svg") no-repeat center;
  -webkit-mask-size: $size;
  mask-size: $size;
  background-color: $color;
}

@mixin articleLine {
  width: 50px;
  height: 6px;
  background-color: $titleBg;
}

@mixin articleTitle {
  font-weight: bold;
  font-size: 3.2rem;
  line-height: 1.6;
  letter-spacing: -0.05em;
  color: $titleBg;
}

@mixin articleSummary {
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
  color: $mainFontColor;
}

@mixin articleDesc {
  font-weight: normal;
  font-size: 2rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
  color: $colorLight;
}

@mixin articleNotice {
  font-size: 2.4rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
  color: $titleBg;
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin removeMask {
  mask: none;
  -webkit-mask: none;
  background-color: transparent;
}

@mixin showFGShape($url) {
  content: "";
  background-image: $url;
  background-size: 41px 70px;
  background-position: -41px -70px;
  width: 41px;
  height: 70px;
  margin: {
    right: 30px;
  };
  display: inline-block;
}

@mixin showFGShapeMobile($url) {
  content: "";
  background-image: $url;
  background-size: 28px 48px;
  background-repeat: no-repeat;
  width: 28px;
  height: 48px;
  display: inline-block;
}

@mixin link($color) {
  color: $color;
  border-bottom: 4px solid $color;
}

@mixin linkMobile($color) {
  border-bottom: 2px solid $color;
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$breakpoint-mobile + 1px}) {
    @content;
  }
}
@include mobile {

  .wrapper {
    width: 100vw;
    height: 826px;

    > .content {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      padding: 100px 30px;

      > .text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        z-index: $zIndexContent;

        > .title {
          @include mainServiceTitleMobile;
          width: 233px;
          height: 44px;
        }

        > .phrase {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          gap: 10px;

          > .summary {
            @include mainServiceSummaryMobile;
            text-align: center;
          }

          > .desc {
            @include mainServiceDescMobile;
            text-align: center;
          }
        }

        > .detailLink {
        }
      }

      > .picture {
        z-index: $zIndexContent;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        @include filterDropshadow(6px, 8px);

        > img {
          width: 300px;
          height: 267px;
        }
      }

      > .bgImageRightTop {
        position: absolute;
        right: 0;
        top: -20px;
        width: 225px;
        height: 351px;
        background: url("../../images/main/about_kpass_bg_01_mobile.svg") no-repeat;
      }

      > .bgImageLeftBottom {
        display: none;
      }
    }
  }
}

@include desktop {
  .wrapper {
    height: 100vh;
    padding-top: $headerHeight;

    > .content {
      @include desktopWidth;
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      padding: {
        left: 60px;
      };

      > .text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        z-index: $zIndexContent;
        max-width: 50%;

        > .title {
          @include mainServiceTitle;
          width: 368px;
        }

        > .phrase {
          @include showBottomToTop(0.5s, 1.1s, 10%);

          > .summary {
            @include mainServiceSummary;
            margin-top: 49px;
          }

          > .desc {
            @include mainServiceDesc;
            margin-top: 30px;
          }
        }

        > .detailLink {
          margin-top: 60px;
          @include showBottomToTop(0.5s, 1.6s, 10%);
        }
      }

      > .picture {
        z-index: $zIndexContent;
        @include filterDropshadow(20px, 14px);
        display: flex;
        flex: 1;
        justify-content: flex-start;
        align-items: center;
        overflow-x: visible;
        position: relative;
        @include showBottomToTop(0.5s, 0.5s, 10%);

        > img {
          position: absolute;
          width: 646.39px;
          height: 579.59px;
          left: 153px;
        }
      }

      > .bgImageRightTop {
        z-index: $zIndexBgItem;
        position: absolute;
        right: -556px;
        top: calc(-145px - #{$headerHeight});
        width: 1035px;
        height: 1119.26px;
        background: url("../../images/main/about_kpass_bg_01.svg") no-repeat;
      }

      > .bgImageLeftBottom {
        z-index: $zIndexBgItem;
        position: absolute;
        bottom: -356.05px;
        left: -474px;
        width: 627px;
        height: 721px;
        background: url("../../images/main/about_kpass_bg_02.svg") no-repeat;
      }
    }
  }
}

