$maxWidth: 1320px;
$rightMenuWidth: 33vw;
$rightMenuWidthMobile: 83vw;
$headerHeight: 68px;
$colorHeavy: #383649;
$colorLight: #757575;
$mainFontColor: #484848;
$footerHeight: 671px;
$footerFontColor: #8F9CAB;
$footerFontColorMobile: #A7A6B5;
$footerBgColor: #223347;
$feelgoodBlue: #5488C7;
$feelgoodYellow: #F4A418;
$zIndexBgItem: -1;
$zIndexContent: 1;
$zIndexSlideLinks: 7;
$zIndexTopBtn: 8;
$zIndexHeader: 9;
$zIndexDrawerMenu: 10;
$zIndexModal: 11;
$titleBg: #2E75CC;
$slideHeight: 100vh;

$breakpoint-mobile: 905px;


@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin mainServiceTitle {
  height: 62px;
  background-color: $titleBg;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 3.2rem;
  letter-spacing: -0.05em;
  color: white;
  border-radius: 31px;
  @include filterDropshadow(4px, 4px);
}

@mixin mainServiceTitleMobile {
  background-color: $titleBg;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.05em;
  color: white;
  border-radius: 100px;
  @include filterDropshadow(4px, 4px);
}

@mixin mainServiceSummary {
  color: $mainFontColor;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin mainServiceSummaryMobile {
  color: $mainFontColor;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin mainServiceDesc {
  color: $colorLight;
  font-weight: normal;
  font-size: 2rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
}

@mixin mainServiceDescMobile {
  color: $colorLight;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.8;
  letter-spacing: -0.05em;
}

@mixin mainSubLink {
  color: $titleBg;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin mainSubLinkMobile {
  color: $titleBg;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.25));
  -webkit-transform: translateZ(0);
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
  -webkit-transform: translateZ(0);
}

@mixin linkRightArrow($color, $size) {
  -webkit-mask: url("/assets/images/right_arrow.svg") no-repeat center;
  mask: url("/assets/images/right_arrow.svg") no-repeat center;
  -webkit-mask-size: $size;
  mask-size: $size;
  background-color: $color;
}

@mixin articleLine {
  width: 50px;
  height: 6px;
  background-color: $titleBg;
}

@mixin articleTitle {
  font-weight: bold;
  font-size: 3.2rem;
  line-height: 1.6;
  letter-spacing: -0.05em;
  color: $titleBg;
}

@mixin articleSummary {
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
  color: $mainFontColor;
}

@mixin articleDesc {
  font-weight: normal;
  font-size: 2rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
  color: $colorLight;
}

@mixin articleNotice {
  font-size: 2.4rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
  color: $titleBg;
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin removeMask {
  mask: none;
  -webkit-mask: none;
  background-color: transparent;
}

@mixin showFGShape($url) {
  content: "";
  background-image: $url;
  background-size: 41px 70px;
  background-position: -41px -70px;
  width: 41px;
  height: 70px;
  margin: {
    right: 30px;
  };
  display: inline-block;
}

@mixin showFGShapeMobile($url) {
  content: "";
  background-image: $url;
  background-size: 28px 48px;
  background-repeat: no-repeat;
  width: 28px;
  height: 48px;
  display: inline-block;
}

@mixin link($color) {
  color: $color;
  border-bottom: 4px solid $color;
}

@mixin linkMobile($color) {
  border-bottom: 2px solid $color;
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$breakpoint-mobile + 1px}) {
    @content;
  }
}
@import "../variables";

.main {
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: {
    top: $headerHeight;
  };
  position: relative;

  > .logo {
    width: 100%;
    padding: {
      top: 150px;
      bottom: 130px;
    };
    display: flex;
    position: relative;

    > img {
      width: 382px;
      height: 73px;
      margin: {
        left: auto;
        right: auto;
      };
    }

    > .bgItem {
      z-index: $zIndexBgItem;
      background-image: url("../../images/sub_sadare/bg_item_01.svg");
      width: 551px;
      height: 504px;
      position: absolute;
      left: calc(50vw + 497px);
      top: 67px;
    }
  }

  > .article01 {
    display: flex;
    @include desktopWidth;

    > .pictureArea {
      flex-shrink: 1;
      width: 536px;
      height: 715px;
      position: relative;

      > img {
        width: 536px;
        position: absolute;
        top: 0;
        right: 0;
        @include filterDropshadow(20px, 14px);
        @include showBottomToTop(0.3s, 0.2s, 10%);
      }

      > .bgItem {
        position: absolute;
        top: 440px;
        left: -264px;
        width: 635px;
        height: 442px;
        background: url("../../images/sub_sadare/bg_item_02.svg");
        z-index: $zIndexBgItem;
      }
    }

    > .textArea {
      flex-shrink: 0;
      padding: {
        left: 80px;
        right: 40px;
      };
      display: flex;
      flex-direction: column;
      justify-content: center;

      > .titleArea {
        @include showBottomToTop(0.3s, 0.5s, 10%);

        > .line {
          @include articleLine;
        }

        > .title {
          @include articleTitle;
          margin-top: 30px;
        }
      }

      > .contentArea {
        @include showBottomToTop(0.3s, 0.8s, 10%);

        > .summary {
          @include articleSummary;
          margin-top: 70px;
        }

        > .desc {
          @include articleDesc;
          margin-top: 70px;
        }
      }

      > .notice {
        @include articleNotice;
        margin-top: 82px;
        @include showBottomToTop(0.3s, 1.1s, 10%);

        > .bold {
          display: block;
          font-weight: bold;
        }

        > .normal {
          display: block;
          font-weight: normal;
        }
      }
    }
  }

  > .article02 {
    margin-top: 170px;
    @include desktopWidth;
    display: flex;
    padding-bottom: 200px;

    > .textArea {
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      text-align: right;
      padding: {
        left: 40px;
        right: 80px;
      };

      > .titleArea {
        @include showBottomToTop(0.3s, 0.5s, 10%);

        > .line {
          @include articleLine;
        }

        > .title {
          @include articleTitle;
          margin-top: 30px;
        }
      }

      > .contentArea {
        @include showBottomToTop(0.3s, 0.8s, 10%);

        > .summary {
          @include articleSummary;
          margin-top: 70px;
        }

        > .desc {
          @include articleDesc;
          margin-top: 70px;
        }
      }


      > .notice {
        @include articleNotice;
        font-weight: normal;
        margin-top: 100px;
        @include showBottomToTop(0.3s, 1.1s, 10%);
      }
    }

    > .pictureArea {
      flex-shrink: 1;
      width: 536px;
      height: 715px;
      position: relative;

      > img {
        width: 536px;
        @include filterDropshadow(20px, 14px);
        @include showBottomToTop(0.3s, 0.2s, 10%);
      }

      > .bgItem {
        z-index: $zIndexBgItem;
        position: absolute;
        left: 300px;
        top: 471px;
        width: 571px;
        height: 615.69px;
        background: url("../../images/sub_sadare/pic_02_bg_item.svg");
      }
    }
  }

  > .linkArea {
    margin-bottom: 250px;

    > .link {
      width: 570px;
      height: 101px;
      background-color: #DEDEDE;
      color: $mainFontColor;
      border-radius: 61px;
      margin: {
        left: auto;
        right: auto;
      };
      font-weight: normal;
      font-size: 3.2rem;
      line-height: 1.6;
      letter-spacing: -0.05em;
      display: flex;
      justify-content: center;
      align-items: center;

      > img {
        width: 50px;
        height: 50px;
        margin-left: 10px;
      }
    }
  }
}