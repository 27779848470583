$maxWidth: 1320px;
$rightMenuWidth: 33vw;
$rightMenuWidthMobile: 83vw;
$headerHeight: 68px;
$colorHeavy: #383649;
$colorLight: #757575;
$mainFontColor: #484848;
$footerHeight: 671px;
$footerFontColor: #8F9CAB;
$footerFontColorMobile: #A7A6B5;
$footerBgColor: #223347;
$feelgoodBlue: #5488C7;
$feelgoodYellow: #F4A418;
$zIndexBgItem: -1;
$zIndexContent: 1;
$zIndexSlideLinks: 7;
$zIndexTopBtn: 8;
$zIndexHeader: 9;
$zIndexDrawerMenu: 10;
$zIndexModal: 11;
$titleBg: #2E75CC;
$slideHeight: 100vh;

$breakpoint-mobile: 905px;

