$maxWidth: 1320px;
$rightMenuWidth: 33vw;
$rightMenuWidthMobile: 83vw;
$headerHeight: 68px;
$colorHeavy: #383649;
$colorLight: #757575;
$mainFontColor: #484848;
$footerHeight: 671px;
$footerFontColor: #8F9CAB;
$footerFontColorMobile: #A7A6B5;
$footerBgColor: #223347;
$feelgoodBlue: #5488C7;
$feelgoodYellow: #F4A418;
$zIndexBgItem: -1;
$zIndexContent: 1;
$zIndexSlideLinks: 7;
$zIndexTopBtn: 8;
$zIndexHeader: 9;
$zIndexDrawerMenu: 10;
$zIndexModal: 11;
$titleBg: #2E75CC;
$slideHeight: 100vh;

$breakpoint-mobile: 905px;


@mixin desktopWidth {
  max-width: $maxWidth;
  margin: {
    left: auto;
    right: auto;
  };
}

@mixin mainServiceTitle {
  height: 62px;
  background-color: $titleBg;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 3.2rem;
  letter-spacing: -0.05em;
  color: white;
  border-radius: 31px;
  @include filterDropshadow(4px, 4px);
}

@mixin mainServiceTitleMobile {
  background-color: $titleBg;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.05em;
  color: white;
  border-radius: 100px;
  @include filterDropshadow(4px, 4px);
}

@mixin mainServiceSummary {
  color: $mainFontColor;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin mainServiceSummaryMobile {
  color: $mainFontColor;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin mainServiceDesc {
  color: $colorLight;
  font-weight: normal;
  font-size: 2rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
}

@mixin mainServiceDescMobile {
  color: $colorLight;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.8;
  letter-spacing: -0.05em;
}

@mixin mainSubLink {
  color: $titleBg;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin mainSubLinkMobile {
  color: $titleBg;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.05em;
}

@mixin filterDropshadow($y, $blur) {
  filter: drop-shadow(0 $y $blur rgba(0, 0, 0, 0.25));
  -webkit-transform: translateZ(0);
}

@mixin filterDropshadowWithColor($y, $blur, $color) {
  filter: drop-shadow(0 $y $blur $color);
  -webkit-transform: translateZ(0);
}

@mixin linkRightArrow($color, $size) {
  -webkit-mask: url("/assets/images/right_arrow.svg") no-repeat center;
  mask: url("/assets/images/right_arrow.svg") no-repeat center;
  -webkit-mask-size: $size;
  mask-size: $size;
  background-color: $color;
}

@mixin articleLine {
  width: 50px;
  height: 6px;
  background-color: $titleBg;
}

@mixin articleTitle {
  font-weight: bold;
  font-size: 3.2rem;
  line-height: 1.6;
  letter-spacing: -0.05em;
  color: $titleBg;
}

@mixin articleSummary {
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
  color: $mainFontColor;
}

@mixin articleDesc {
  font-weight: normal;
  font-size: 2rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
  color: $colorLight;
}

@mixin articleNotice {
  font-size: 2.4rem;
  line-height: 1.8;
  letter-spacing: -0.05em;
  color: $titleBg;
}

@mixin showBottomToTop($durationVal, $delayVal, $translateYVal) {
  transition: all $durationVal ease-in-out;
  transition-delay: $delayVal;
  transform: translateY($translateYVal);
  opacity: 0;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.hide {
    transition: all 0s;
    transition-delay: 0.5s;
  }
}

@mixin removeMask {
  mask: none;
  -webkit-mask: none;
  background-color: transparent;
}

@mixin showFGShape($url) {
  content: "";
  background-image: $url;
  background-size: 41px 70px;
  background-position: -41px -70px;
  width: 41px;
  height: 70px;
  margin: {
    right: 30px;
  };
  display: inline-block;
}

@mixin showFGShapeMobile($url) {
  content: "";
  background-image: $url;
  background-size: 28px 48px;
  background-repeat: no-repeat;
  width: 28px;
  height: 48px;
  display: inline-block;
}

@mixin link($color) {
  color: $color;
  border-bottom: 4px solid $color;
}

@mixin linkMobile($color) {
  border-bottom: 2px solid $color;
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$breakpoint-mobile + 1px}) {
    @content;
  }
}
@include mobile {

  .main {
    width: 100%;
    overflow-x: hidden;
    //height: 100%;
    //overflow-y: scroll;
    display: flex;
    flex-direction: column;
    margin-top: $headerHeight;
    position: relative;

    > .article01 {
      width: 100%;
      padding: {
        top: 100px;
      };
      position: relative;

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 80px;

      > .bgItem01 {
        background: url("../../images/sub_kpass/bg_item_01_mobile.svg") no-repeat;
        width: 130px;
        height: 119px;
        position: absolute;
        top: -28px;
        left: 13px;
        z-index: $zIndexBgItem;
      }

      > .logo {
        width: 154px;
        height: 55px;

        > img {
          width: 100%;
        }
      }

      > .texts {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        padding: {
          left: 30px;
          right: 30px;
        };

        > .summary {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;

          font-weight: bold;
          font-size: 16px;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: $mainFontColor;
        }

        > .desc {
          max-height: 9999px;
          text-align: center;

          font-weight: normal;
          font-size: 14px;
          line-height: 1.8;
          letter-spacing: -0.05em;
          color: $colorLight;
        }
      }

    }

    > .article02 {
      display: flex;
      flex-direction: column;
      gap: 80px;
      align-items: center;

      padding: 100px 0;
      position: relative;

      > .bgItem {
        background: url("../../images/sub_kpass/bg_item_02_mobile.svg") no-repeat;
        width: 210px;
        height: 146px;
        position: absolute;
        bottom: -76px;
        right: -40px;
        z-index: $zIndexBgItem;
      }

      > .pictureArea {
        width: 298px;
        height: 272px;
        position: relative;

        > .bgItem {
          position: absolute;
          background: url("../../images/sub_kpass/bg_item_03.svg");
          background-size: 100% 100%;
          width: 149.53px;
          height: 172.9px;
          left: 0;
          top: 0;
          z-index: $zIndexBgItem;
        }

        > .picture {
          position: absolute;
          right: 0;
          bottom: 0;
          z-index: $zIndexContent;
          @include filterDropshadow(6px, 8px);

          > img {
            width: 253.77px;
            height: 208.74px;
          }
        }
      }

      > .textArea {
        width: 100vw;
        z-index: $zIndexContent;
        display: flex;
        flex-direction: column;
        gap: 30px;
        align-items: center;

        > .titleArea {
          width: 100%;
          padding: 0 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;

          > .line {
            width: 35px;
            height: 4px;
            background-color: $titleBg;
          }

          > .title {
            font-weight: bold;
            font-size: 20px;
            line-height: 1.6;
            letter-spacing: -0.05em;
            color: $titleBg;
            text-align: center;
          }
        }

        > .desc {
          width: 100%;
          padding: 0 30px;
          display: flex;
          flex-direction: column;
          align-items: center;

          > .bold {
            line-height: 1.8;
            letter-spacing: -0.05em;
            color: $colorLight;
            text-align: center;
            font-size: 14px;
          }
        }
      }
    }

    > .openNotice {
      display: flex;
      flex-direction: column;

      > .notice {
        background: url("../../images/sub_kpass/bg_02_mobile.svg") repeat-x;
        width: 100%;
        height: 360px;
        position: relative;
        padding-top: 80px;

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        > .bgItem01 {
          position: absolute;
          right: calc(50vw - 24px);
          top: 248px;
          width: 174px;
          height: 220px;
          background: url("../../images/sub_kpass/bg_02_item_01_mobile.png") no-repeat;
          background-size: 100%;
        }

        > .bgItem02 {
          position: absolute;
          left: calc(50vw + 24px);
          top: 263px;
          width: 126px;
          height: 205px;
          background: url("../../images/sub_kpass/bg_02_item_02_mobile.png") no-repeat;
          background-size: 100%;
        }

        > .title {
          display: block;
          font-weight: bold;
          font-size: 28px;
          line-height: 1.8;
          letter-spacing: -0.05em;
          color: white;
        }

        > .desc {
          padding: 0 30px;
          display: block;
          font-weight: normal;
          font-size: 16px;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: white;
          text-align: center;
        }
      }

      > .linkArea {
        width: 100%;
        height: 312px;
        display: flex;
        justify-content: center;

        > .link {
          margin-top: 162px;
          display: flex;
          width: 248px;
          height: 50px;
          border-radius: 100px;
          background-color: $footerBgColor;
          @include filterDropshadow(4px, 4px);
          font-weight: bold;
          font-size: 14px;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;

          > .arrow {
            display: inline-block;
            width: 22px;
            height: 22px;
            @include linkRightArrow(white, 22px);
          }
        }
      }
    }
  }
}

@include desktop {
  .main {
    width: 100%;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    padding: {
      top: $headerHeight;
    };
    position: relative;

    > .article01 {
      @include desktopWidth;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: {
        top: 150px;
      };
      position: relative;

      > .bgItem01 {
        background: url("../../images/sub_kpass/bg_item_01.svg") no-repeat;
        width: 351px;
        height: 321px;
        position: absolute;
        top: -69px;
        left: -123px;
        z-index: $zIndexBgItem;
      }

      > .bgItem02 {
        background: url("../../images/sub_kpass/bg_item_02.svg") no-repeat;
        width: 635px;
        height: 442px;
        position: absolute;
        top: 449px;
        right: -455px;
        z-index: $zIndexBgItem;
      }

      > .logo {
        width: 288px;
        height: 102px;

        > img {
          width: 100%;
        }
      }

      > .texts {
        @include showBottomToTop(0.3s, 0.2s, 10%);
        display: flex;
        flex-direction: column;
        gap: 30px;

        > .summary {
          margin-top: 90px;
          font-weight: bold;
          font-size: 2.4rem;
          line-height: 1.8;
          letter-spacing: -0.05em;
          text-align: center;
          color: $mainFontColor;
        }

        > .desc {
          font-weight: normal;
          font-size: 2rem;
          line-height: 1.8;
          letter-spacing: -0.05em;
          color: $colorLight;
          text-align: center;
        }
      }

    }

    > .article02 {
      @include desktopWidth;
      display: flex;
      align-items: center;
      padding: {
        top: 155px;
        bottom: 200px;
      };

      > .pictureArea {
        width: 676px;
        height: 554px;
        flex-shrink: 1;
        position: relative;

        > .bgItem {
          position: absolute;
          background: url("../../images/sub_kpass/bg_item_03.svg");
          width: 401px;
          height: 461px;
          left: -146px;
          top: -169px;
          z-index: $zIndexBgItem;
        }

        > .picture {
          position: absolute;
          right: 0;
          width: 676px;
          height: 554px;
          z-index: $zIndexContent;
          @include filterDropshadow(20px, 14px);
          @include showBottomToTop(0.3s, 0.2s, 10%);
        }
      }

      > .textArea {
        z-index: $zIndexContent;
        flex-shrink: 0;
        padding: {
          left: 100px;
          right: 103px;
        };

        > .titleArea {
          @include showBottomToTop(0.3s, 0.5s, 10%);

          > .line {
            width: 50px;
            height: 6px;
            background-color: $titleBg;
          }

          > .title {
            margin-top: 30px;
            font-weight: bold;
            font-size: 3.2rem;
            line-height: 1.6;
            letter-spacing: -0.05em;
            color: $titleBg;
          }
        }

        > .desc {
          margin-top: 70px;
          font-size: 2rem;
          line-height: 1.8;
          letter-spacing: -0.05em;
          @include showBottomToTop(0.3s, 0.8s, 10%);

          > .bold {
            font-weight: bold;
            color: $mainFontColor;
          }
        }
      }

      > .textArea.ru {
        max-width: 50%;
      }
    }

    > .openNotice {

      > .notice {
        background: url("../../images/sub_kpass/bg_02.svg") repeat-x;
        width: 100%;
        height: 582px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;

        > .bgItem01 {
          position: absolute;
          left: calc(50vw - 720px);
          top: 78px;
          width: 401px;
          height: 601px;
          background: url("../../images/sub_kpass/bg_02_item_01.png") no-repeat;
        }

        > .bgItem02 {
          position: absolute;
          left: calc(50vw + 210px);
          top: 205px;
          width: 494px;
          height: 455px;
          background: url("../../images/sub_kpass/bg_02_item_02.svg") no-repeat;
        }

        > .title {
          display: block;
          font-weight: bold;
          font-size: 6rem;
          line-height: 1.8;
          letter-spacing: -0.05em;
          color: white;
          @include showBottomToTop(0.3s, 0.2s, 10%);
        }

        > .desc {
          display: block;
          margin-top: 20px;
          font-weight: normal;
          font-size: 3.2rem;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: white;
          text-align: center;
          @include showBottomToTop(0.3s, 0.5s, 10%);
        }
      }

      > .linkArea {
        width: 100%;
        height: 519px;
        display: flex;
        align-items: center;
        justify-content: center;

        > .link {
          display: block;
          width: 536px;
          height: 101px;
          border-radius: 61px;
          background-color: $footerBgColor;
          @include filterDropshadow(4px, 4px);
          font-weight: bold;
          font-size: 3.2rem;
          line-height: 1.6;
          letter-spacing: -0.05em;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;

          > .arrow {
            display: inline-block;
            width: 50px;
            height: 50px;
            @include linkRightArrow(white, 50px);
          }
        }
      }
    }
  }
}

